<template>
  <div class="item_people" :style="itemPeopleStyle">
    <!-- 드래그시 drag_on 클래스 추가 -->
    <div class="btn_nick" :class="typeMeClass">
      <!-- 자기 자신 결재선 타입일 때 : type_me 클래스 추가 -->
      <div :data-key="dataKey" class="target_name">
        {{ displayEmp }}
      </div>
      <!-- 드래그시 타겟 요소 -->
      <a href="javascript:void(0);" class="link_del" @click.prevent="removeItem">
        <span class="ico_account ico_cross_primary">삭제</span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { commConst } from "@/_approval/const/const.js";

export default {
  name: "CommItemEmp",
  props: {
    empName: String,
    accountId: String,
    isTypeMe: {
      type: Boolean,
      default: false,
    },
    isTypeFix: {
      type: Boolean,
      default: false,
    },
    dataKey: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      typeMeClass: "",
      itemPeopleStyle: "",
    };
  },
  computed: {
    ...mapState("CommStore", {
      login: (state) => state.login,
    }),
    displayEmp() {
      let displayEmp = "";
      if (this.login.cmpCode === commConst.DKT_CMP_CODE) {
        displayEmp = this.empName + "(" + this.accountId + ")";
      } else {
        displayEmp = this.empName + "(" + this.accountId + ")";
      }
      return displayEmp;
    },
  },
  created() {
    if (this.isTypeMe) {
      this.typeMeClass = "type_me";
      this.itemPeopleStyle = "pointer-events:none"; // 드래그 안 되게 처리
    } else {
      if (this.isTypeFix) {
        this.typeMeClass = "type_fix";
        this.itemPeopleStyle = "pointer-events:none";
      } else {
        this.typeMeClass = "";
        this.itemPeopleStyle = "";
      }
    }
  },
  methods: {
    removeItem() {
      this.$emit("remove-employee");
    },
  },
};
</script>
